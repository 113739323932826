import request from '@/utils/request'

const store = {

 // 题库列表
  questionList(data) {
    return request({
      url: `/question/list`,
      method: 'post',
      data
    })
  },

  updateName(data) {
    return request({
      url: `/question/updateName`,
      method: 'post',
      data
    })
  },
  
  // 题库名称列表查询
  questionStoreList(data) {
    return request({
      url: `/question/names`,
      method: 'post',
      data
    })
  },

}
export default store
