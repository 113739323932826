import Cookies from 'js-cookie'

const ManageTokenKey = 'sat_manage_token'
const UserTokenKey = "sat_user_token"

export const ManageToken = {

  getToken: function getToken() {
    return Cookies.get(ManageTokenKey)
  },
  
  setToken: function setToken(token) { 
    return Cookies.set(ManageTokenKey, token)
  },

  removeToken: function removeToken() {
    return Cookies.remove(ManageTokenKey)
  }

}

export const UserToken = {

  getToken: function getToken() {
    return Cookies.get(UserTokenKey)
  },
  
  setToken: function setToken(token) { 
    return Cookies.set(UserTokenKey, token)
  },

  removeToken: function removeToken() {
    return Cookies.remove(UserTokenKey)
  }

}


// export function getToken() {
//   return Cookies.get(ManageTokenKey)
// }

// export function setToken(token) {
//   return Cookies.set(ManageTokenKey, token)
// }

// export function removeToken() {
//   return Cookies.remove(ManageTokenKey)
// }
