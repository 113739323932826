import request from '@/utils/request'

const member = {

 // 用户列表查询
  userList(data) {
    return request({
      url: `/user/list`,
      method: 'post',
      data
    })
  },

  addUser(data) {
    return request({
      url: `/user/new`,
      method: 'post',
      data
    })
  },
  
  updateUser(data) {
    return request({
      url: `/user/update`,
      method: 'post',
      data
    })
  },

  deleteUser(data) {
    return request({
      url: `/user/delete`,
      method: 'post',
      data
    })
  },

  // 用户角色关联查询
  roleQuery(data) {
    return request({
      url: `/user/role/query`,
      method: 'post',
      data
    })
  },

  //用户角色关联修改 
  userRoleUpdate(data) {
    return request({
      url: `/user/role/update`,
      method: 'post',
      data
    })
  },

  login(data) {
    return request({
      url: `/user/login`,
      method: 'post',
      data
    })
  },

  myauth(data) {
    return request({
      url: `/user/auth`,
      method: 'get',
      data
    })
  }

}
export default member
