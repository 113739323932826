// api总入口
import topic from './topic'
import question from './question'
import system from './system'


const api = {
  topic,
  question,
  system
}

export default api
