import request from '@/utils/request'

const topic = {

  list(data){
    return request({
      url: `/topic/list`,
      method: 'post',
      data
    })
  },
  updateList(data) {
    return request({
      url: `/topic/updateList`,
      method: 'post',
      data
    })
  },
  
  // 题目详情
  details(data) {
    return request({
      url: `/topic/details`,
      method: 'post',
      data
    })
  },

  //题目状态修改
  topicUpdate(data) {
    return request({
      url: `/topic/update`,
      method: 'post',
      data
    })
  }
  
}
export default topic
