import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/manage/layout/index'

Vue.use(VueRouter)


const userRoutes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/index/Index')
  },
  {
    path: '/students',
    name: 'students',
    component: () => import(/* webpackChunkName: "students" */ '../views/students')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/Login')
  },
  {
    path: '/answer/tips',
    name: 'answer-tips',
    component: () => import(/* webpackChunkName: "answer-tips" */ '../views/answer/PracticeTips')
  },
  {
    path: '/answer/view',
    name: 'answer-view',
    component: () => import(/* webpackChunkName: "answer-view" */ '../views/answer/View')
  },
  {
    path: '/answer/index',
    name: 'answer-index',
    component: () => import(/* webpackChunkName: "answer-index" */ '../views/answer/Index')
  },
  {
    path: '/answer/choose',
    name: 'answer-choose',
    component: () => import(/* webpackChunkName: "answer-choose" */ '../views/answer/Choose')
  },
  {  
    path: '/manage/login',
    name: 'manage-login',
    component: () => import(/* webpackChunkName: "manage-login" */ '../views/manage/login')
  },
  {
    path: '/demo',
    name: 'demo',
    component: () => import(/* webpackChunkName: "demo" */ '../views/demo')
  },
  {
    path: '/demo/userid',
    name: 'demo-userid',
    component: () => import(/* webpackChunkName: "demo-userid" */ '../views/demo/userid')
  },
  {
    path: '/manage/question/simplify/answer',
    name: 'question-simplify-answer',
    component: () => import(/* webpackChunkName: "question-simplify-answer" */ '../views/manage/question/simplify/answer')
  },
]

const manageRoutes = [
  
  {
    path: '/manage',
    component: Layout,
    redirect: '/manage/index',
    children: [
      {
        path: '401',
        name: 'error-401',
        component: () => import(/* webpackChunkName: "error-401" */ '../views/error/401')
      },
      {  
        path: 'index',
        name: 'manage',
        component: () => import(/* webpackChunkName: "manage-index" */ '../views/manage/index')
      },
      {  
        path: 'role',
        name: 'role',
        component: () => import(/* webpackChunkName: "role" */ '../views/manage/system/role')
      },
      {  
        path: 'machine',
        name: 'machine',
        component: () => import(/* webpackChunkName: "machine" */ '../views/manage/system/machine')
      },
      {  
        path: 'member',
        name: 'member',
        component: () => import(/* webpackChunkName: "member" */ '../views/manage/system/member')
      },
      {  
        path: 'permission',
        name: 'permission',
        component: () => import(/* webpackChunkName: "permission" */ '../views/manage/system/permission')
      },
      {
        path: 'question/teacher',
        name: 'question-teacher',
        component: () => import(/* webpackChunkName: "question-teacher" */ '../views/manage/question/teacher')
      },
      {
        path: 'question/teacher/answer',
        name: 'question-teacher-answer',
        component: () => import(/* webpackChunkName: "question-teacher-answer" */ '../views/manage/question/teacher/answer')
      },
      {
        path: 'question/simplify',
        name: 'question-simplify',
        component: () => import(/* webpackChunkName: "question-simplify" */ '../views/manage/question/simplify')
      },
      {
        path: 'system/machine',
        name: 'system-machine',
        component: () => import(/* webpackChunkName: "system-machine" */ '../views/manage/system/machine')
      },
      {
        path: 'question/store',
        name: 'question-store',
        component: () => import(/* webpackChunkName: "question-store" */ '../views/manage/question/store')
      },
      {
        path: 'question/store/updateList',
        name: 'question-store-updateList',
        component: () => import(/* webpackChunkName: "question-store-updateList" */ '../views/manage/question/store/updateList')
      },
      {
        path: 'log',
        name: 'log',
        component: () => import(/* webpackChunkName: "log" */ '../views/manage/system/log')
      },

      
    ]
  }
]

const routes = [
  ...userRoutes,
  ...manageRoutes
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
