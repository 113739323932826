import request from '@/utils/request'

const role = {

  list(data) {
    return request({
      url: `/user/role/list`,
      method: 'post',
      data
    })
  },

  new(data) {
    return request({
      url: `/user/role/new`,
      method: 'post',
      data
    })
  },

  delete(data) {
    return request({
      url: `/user/role/delete`,
      method: 'post',
      data
    })
  },

  // 角色权限关联修改
  permissionUpdate(data) {
    return request({
      url: `/user/permission/update`,
      method: 'post',
      data
    })
  },

  // 角色权限关联查询
  permissionQuery(data) {
    return request({
      url: `/user/permission/query`,
      method: 'post',
      data
    })
  }

  

}
export default role
