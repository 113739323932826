<template>
  <div>
    <div class="container" v-if="role">
      <div class="header">
        <span class="logo">SAT模考系统管理</span>
        <span>
          <span class="mr-8">{{ username }}</span>
          [<span class="mr-8" v-for="(item, index) in role" :key="index">{{ item.explain }}</span>]
          <span><el-link type="danger" @click="logout">退出</el-link></span>
        </span>
      </div>
      <div class="main">
        <div class="left">
          <SideBar :role="role"></SideBar>
        </div>
        <div class="right">
          <keep-alive>
            <MainContainer />
          </keep-alive>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from './SideBar'
import MainContainer from './MainContainer'
import { ManageToken } from '@/utils/token'
export default {
  name: 'index',
  components: {
    SideBar,
    MainContainer
  },
  data() {
    return {
      role: "",
      username: "",
      userPermissions: []
    }
  },
  mounted() {
    this.initUserinfo()
  },
  methods: {
    logout() {
      localStorage.removeItem("sat_manage_my_auth")
      localStorage.removeItem("sat_manage_username")
      ManageToken.removeToken()
      this.$router.push("/")
    },
    initUserinfo() {
      if (localStorage.getItem("sat_manage_my_auth")) {
        let sat_manage_my_auth = JSON.parse(localStorage.getItem("sat_manage_my_auth")) || ""
        if (sat_manage_my_auth) {
          this.role = sat_manage_my_auth.userRoles
          this.userPermissions = sat_manage_my_auth.userPermissions
          // console.log(this.userPermissions)
        }
        this.username = localStorage.getItem("sat_manage_username") || ""
      } else {
        this.$router.push("/")
      }
      
    }
  },
}
</script>

<style lang='scss' scoped>
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  border-bottom: 1px solid #eee;
  padding: 0px 20px;
  .logo {
    font-size: 20px;
  }
}
.main {
  display: flex;
  .left {
  }
  .right {
    flex:1;
    padding: 15px;
    height: calc(100vh - 60px);
    overflow: auto;
  }
}
</style>