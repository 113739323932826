import request from '@/utils/request'

const log = {
  
  queryLog(data) {
    return request({
      url: `/log/list`,
      method: 'post',
      data
    })
  },
  addLog(data) {
    return request({
      url: `/log/new`,
      method: 'post',
      data
    })
  }

}
export default log
