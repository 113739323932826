import request from '@/utils/request'

const permission = {

  list(data) {
    return request({
      url: `/user/permission/list`,
      method: 'post',
      data
    })
  },

  
}
export default permission
