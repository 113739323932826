import api from '@/api'
import { ManageToken, UserToken } from '@/utils/token'
import md5 from 'js-md5'

//定义状态
const state = {
  adminToken: ManageToken.getToken()
}

//改变状态值
const mutations = {
  SET_TOKEN(state, data){
    state.adminToken = data
    ManageToken.setToken(data)
  }
}

const actions = {
  /*
   * 异步方法写到这里
   * ctx:上下文
   * payload:传入的参数对象
  */
  login(ctx, payload){
    //payload为参数对象、 如果要单独拿参数用解构，否则直接将所有参数传入
    return new Promise((resolve, reject) => {
      // 提交数据前MD5加密传送
      let formData = { username: payload.username, password: md5(payload.password) }
      console.log(formData)
      api.system.member.login(formData).then(res => {
        if (res.code === 200) {
          ctx.commit('SET_TOKEN', res.data.tokenValue)
        }
        resolve(res)
      }).catch(error => {
        console.log(error)
        reject(error)
      })
    })
  }


}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
