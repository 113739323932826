import machine from './machine'
import member from './member'
import role from './role'
import permission from './permission'
import log from './log'

const system = {
  machine,
  member,
  role,
  permission,
  log
}

export default system